/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '@/components/Section';
import Title from '@/components/Title';
import Text from '@/components/Text';

const Privacy = () => {
  const { contentsJson } = useStaticQuery(
    graphql`
      query privacyQuery {
        contentsJson(privacy: { id: { eq: "privacy" } }) {
          privacy {
            title
            items {
              list
              text
            }
            version
          }
        }
      }
    `
  );

  const { title, items, version } = contentsJson.privacy;

  return (
    <Section>
      <Title>{title}</Title>
      {items.map(({ text, list }, i) => (
        <Fragment key={i}>
          <Text>{text}</Text>
          {list.map((str, j) => (
            <Text key={j} margin="0">
              {str}
            </Text>
          ))}
        </Fragment>
      ))}
      <Text>{version}</Text>
    </Section>
  );
};

export default Privacy;
export { default as Head } from '@/components/Head';
